import logo from './pinup.svg';
import './App.css';

function App() {
  return (
      <div className="App">
        <div className="susie">
      </div>
        <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
          <a
            className="App-link"
            href="/ssc.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Susie Sweet Cheeks
          </a>
        </header>
      
    </div>
  );
}

export default App;
